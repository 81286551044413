import styles from "./index.module.less";
import { useUserProvider } from "@/context/UserProvider";
const Index = () => {
  const { onOpenLoginModal } = useUserProvider();
  return (
    <div className={styles.container}>
      <span onClick={() => onOpenLoginModal(true)}>Join now</span> to start
      monitoring and removing leaked content.
    </div>
  );
};

export default Index;
