import React from "react";
import style from "./index.module.less";
import Image from "next/image";
const Index: React.FC<any> = ({ showProInfo }) => {
  return (
    <div className={style.container}>
      {<h2>{showProInfo.title}</h2>}
      <ul className={style.mainList}>
        {showProInfo.mainList.map((item: any, index: number) => (
          <li key={index}>
            <Image
              src={item.img}
              alt={item.title}
              height={80}
              width={80}
            ></Image>
            <div className={style.title}>{item.title}</div>
            <div className={style.desc}>{item.desc}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Index;
