import React from "react";
import style from "./index.module.less";
import Image from "next/image";
const Index: React.FC<any> = ({ showInfo }) => {
  return (
    <div className={style.container}>
      <h2>
        <span>{showInfo.title1}</span>
        <span>{showInfo.title2}</span>
      </h2>
      <ul className={style.main}>
        {showInfo.mainList.map((item: any, index: number) => (
          <li key={index} style={{ backgroundColor: showInfo.color }}>
            <div className={style.left}>
              <div>{item.title}</div>
              <div>{item.main}</div>
            </div>
            <div className={style.right}>
              <Image
                src={item.img}
                alt={item.title}
                width={170}
                height={161}
                layout="responsive"
              ></Image>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Index;
