import {
  useMemo,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import style from "./index.module.less";
import OurModal from "@/components/Modal/index";
import { Dropdown, Input, Button, message } from "antd";
import type { MenuProps } from "antd";
import { DownOutlined, CopyOutlined, CloseOutlined } from "@ant-design/icons";
import {
  postSeachSocialAccountApi,
  postAccountAddApi,
  postEditAccountStatustApi,
} from "@/lib/service";
import { useUserProvider } from "@/context/UserProvider";
import { useRouter } from "next/router";
import { platFormsIconsMap } from "@/lib/hooks/utils/plateforms";
import { useConfigProvider } from "@/context/ConfigProvider";

interface Iprops {
  isModalOpen: boolean;
  handleCancel: () => void;
  upDataAccountList?: () => void;
  isHome?: boolean;
  ref?: any;
}
// 1

const Index: React.FC<Iprops> = forwardRef(
  ({ isModalOpen, handleCancel, upDataAccountList, isHome = false }, ref) => {
    const router = useRouter();
    const [step, setStep] = useState(0);
    const [innerStep, setInnerStep] = useState(0);
    const [selectRow, setSelectRow] = useState("");
    const [pathname, setPathname] = useState("");
    const [step1Loading, setStep1Loading] = useState(false);
    const [info, setInfo] = useState<any>({});
    const [isCopy, setIsCopy] = useState(false);
    const { isLogin, onOpenLoginModal, userSub } = useUserProvider();
    const [isEdit, setIsEdit] = useState(false);
    const { isMobile } = useConfigProvider();
    useImperativeHandle(ref, () => ({
      trigger(row: any) {
        setInfo(row);
        setSelectRow(row.url);
        setInnerStep(2);
        setStep(1);
        setIsEdit(true);
      },
    }));

    // 免责声明
    const [isDisclaimerModal, setIsDisclasimerModal] = useState(false);
    const items: MenuProps["items"] = [
      {
        key: "1",
        type: "group",
        label: "SUBCRIPTION ACCOUNTS",
        children: [
          {
            key: "1-1",
            label: (
              <div onClick={() => setSelectRow("https://onlyfans.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/onlyfans.png"
                  alt=""
                />
                <span style={{}}>onlyfans.com</span>
              </div>
            ),
          },
          {
            key: "1-2",
            label: (
              <div onClick={() => setSelectRow("https://tiktok.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/tiktok.png"
                  alt=""
                />
                <span>tiktok.com</span>
              </div>
            ),
          },
          {
            key: "1-3",
            label: (
              <div onClick={() => setSelectRow("https://X.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/space.png"
                  alt=""
                />
                <span>x.com</span>
              </div>
            ),
          },
          {
            key: "1-4",
            label: (
              <div onClick={() => setSelectRow("https://instagram.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/ins.png"
                  alt=""
                />
                <span>instagram.com</span>
              </div>
            ),
          },
          {
            key: "1-5",
            label: (
              <div onClick={() => setSelectRow("https://patreon.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/patreon.png"
                  alt=""
                />
                <span>patreon.com</span>
              </div>
            ),
          },

          {
            key: "1-6",
            label: (
              <div onClick={() => setSelectRow("https://chaturbate.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/chaturbate.png"
                  alt=""
                />
                <span>chaturbate.com</span>
              </div>
            ),
          },
          {
            key: "1-7",
            label: (
              <div onClick={() => setSelectRow("https://fansly.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/fansly.png"
                  alt=""
                />
                <span>fansly.com</span>
              </div>
            ),
          },
          {
            key: "1-8",
            label: (
              <div onClick={() => setSelectRow("https://fanvue.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/fanvue.png"
                  alt=""
                />
                <span>fanvue.com</span>
              </div>
            ),
          },
          {
            key: "1-9",
            label: (
              <div onClick={() => setSelectRow("https://manyvids.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/manyvids.png"
                  alt=""
                />
                <span>manyvids.com</span>
              </div>
            ),
          },
          {
            key: "1-10",
            label: (
              <div onClick={() => setSelectRow("https://reddit.com/")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/reddit.png"
                  alt=""
                />
                <span>reddit.com</span>
              </div>
            ),
          },
        ],
      },
      {
        key: "2",
        type: "group",
        label: "OTHERS",
        children: [
          {
            key: "2-1",
            label: (
              <div onClick={() => setSelectRow("")}>
                <img
                  style={{
                    height: "20px",
                    verticalAlign: "middle",
                    marginRight: "5px",
                  }}
                  src="/images/erasa/platforms/other.png"
                  alt=""
                />
                <span>Others</span>
              </div>
            ),
          },
        ],
      },
    ];
    const iconUrlMapRef = useRef(
      new Map([
        ["https://onlyfans.com/", "/images/erasa/platforms/onlyfans.png"],
        ["https://tiktok.com/", "/images/erasa/platforms/tiktok.png"],
        ["https://X.com/", "/images/erasa/platforms/space.png"],
        ["https://instagram.com/", "/images/erasa/platforms/ins.png"],
        ["https://www.patreon.com/", "/images/erasa/platforms/patreon.png"],
        [
          "https://www.chaturbate.com/",
          "/images/erasa/platforms/chaturbate.png",
        ],
        ["https://www.fansly.com/", "/images/erasa/platforms/fansly.png"],
        ["https://www.fanvue.com/", "/images/erasa/platforms/fanvue.png"],
        ["https://www.manyvids.com/", "/images/erasa/platforms/manyvids.png"],
        ["https://www.reddit.com/", "/images/erasa/platforms/reddit.png"],
        ["", "/images/erasa/platforms/other.png"],
      ])
    );

    const socialType = useMemo(() => {
      if (selectRow) {
        return selectRow.match(/\/\/(.*?)\./)[1];
      } else {
        return "";
      }
    }, [selectRow]);
    //   确定 url
    const handleConfirmUrlClick = () => {
      // 需求如此
      setInnerStep(1);
      let timer = setTimeout(() => {
        postSeachSocialAccountApi({
          username: pathname,
          social_type: socialType,
          url: selectRow + pathname,
        }).then((res) => {
          if (res.code == 0) {
            clearTimeout(timer);
            setInnerStep(2);
            setInfo(res.data);
          } else {
            message.warning(res.message);
            setInnerStep(0);
          }
        });
      }, 1500);
    };
    // 下一步
    const handleNext = () => {
      if (innerStep == 0 || innerStep == 1) {
        return;
      }
      if (isHome && !isLogin) {
        onOpenLoginModal(true);
      } else {
        if (isHome && !userSub?.item_id) {
          router.push(`/setting?social_account_id=${info.id}`);
        } else {
          if (userSub?.item_id) {
            setStep(1);
          } else {
            router.push("/plan");
          }
        }
      }
    };
    // 重置
    const resetStatus = () => {
      setStep(0);
      setInnerStep(0);
      setPathname("");
      setSelectRow("");
      setInfo({});
      setIsEdit(false);
      setIsCopy(false);
      handleCancel();
    };

    // 复制
    const handleCopy = (val: string) => {
      setIsCopy(true);
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      message.success("Content to copy to clipboard");
    };
    // 校验辅助函数
    const verifyDescFunc = (description: any) => {
      if (description) {
        if (description.indexOf("erasa.ai") == -1) {
          return 2;
        } else {
          return 1;
        }
      } else {
        return 2;
      }
    };
    // 校验 desc
    const handlVerify = () => {
      setStep1Loading(true);
      if (isEdit) {
        // 编辑
        postSeachSocialAccountApi({
          username: info.username,
          social_type: socialType,
          url: info.url,
        }).then((resIns) => {
          postEditAccountStatustApi(info.id, {
            verify_status: verifyDescFunc(resIns.data.profile_description),
          }).then((rs) => {
            if (rs.code == 0) {
              upDataAccountList && upDataAccountList();
              resetStatus();
              setStep1Loading(false);
            } else {
              message.warning(rs.message);
            }
          });
        });
      } else {
        // 新增
        if (isCopy) {
          // 验证
          postSeachSocialAccountApi({
            username: pathname,
            social_type: socialType,
            url: selectRow + pathname,
          }).then((resIns) => {
            postAccountAddApi({
              url: selectRow + pathname,
              name: info?.name,
              username: info?.username,
              avatar: info?.avatar_url,
              platform: socialType,
              description: info?.profile_description,
              verify_status: verifyDescFunc(resIns.data.profile_description),
            }).then((rs) => {
              if (rs.code == 0) {
                message.success(rs.message);
                upDataAccountList && upDataAccountList();
                resetStatus();
                setStep1Loading(false);
                setIsDisclasimerModal(true);
              } else {
                message.warning(rs.message);
              }
            });
          });
        } else {
          // 没有验证
          postAccountAddApi({
            url: selectRow + pathname,
            name: info?.name,
            username: info?.username,
            avatar: info?.avatar_url,
            platform: socialType,
            description: info?.profile_description,
            verify_status: 0,
          }).then((rs) => {
            if (rs.code == 0) {
              upDataAccountList && upDataAccountList();
              resetStatus();
              setStep1Loading(false);
              setIsDisclasimerModal(true);
            } else {
              message.warning(rs.message);
            }
          });
        }
      }
    };

    return (
      <div className={style.container}>
        <OurModal
          isModalOpen={isModalOpen}
          isNoAddAccount={false}
          handleCancel={() => {
            resetStatus();
          }}
          width={isMobile ? 400 : 850}
          errIconOut={true}
        >
          <div className={style.main}>
            <div className={style.left}>
              <div className={style.titleStep}>
                <div>{step == 0 ? "Add account" : "Verify yourself"}</div>
                <div>
                  <span>Step:</span>
                  <span className={step == 0 ? style.active : ""}>1</span>
                  <span className={step == 1 ? style.active : ""}>2</span>
                </div>
              </div>
              {step == 0 ? (
                <>
                  <div className={style.desc}>
                    Select the social media accounts you want Erasa to protect.
                    Without this, we won’t be able to protect your content.
                  </div>
                  <div className={style.platformsItem}>Platforms</div>
                  <div className={style.plateforms}>
                    <Dropdown
                      autoAdjustOverflow={false}
                      menu={{ items }}
                      trigger={["click"]}
                      placement="bottom"
                      disabled={innerStep == 1 || innerStep == 2}
                    >
                      <div className={style.addPlatform}>
                        <div>
                          <img
                            src={iconUrlMapRef.current.get(selectRow)}
                            alt=""
                          />
                          <span>{selectRow || "Add Account"}</span>
                        </div>
                        <div>
                          <DownOutlined />
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                  <div className={style.platformsItem}>
                    <div>Add account handle</div>
                  </div>
                  <div className={style.account}>
                    {innerStep == 0 ? (
                      <div className={style.iptAction}>
                        <span>{selectRow}</span>
                        <Input
                          className={style.ipt}
                          onChange={(e: any) => setPathname(e.target.value)}
                          placeholder="type-your-handie-here"
                        ></Input>
                        {pathname && (
                          <div
                            className={style.ok}
                            onClick={handleConfirmUrlClick}
                          >
                            <img
                              src="/images/erasa/protectedAccount/rightIcon.webp"
                              alt=""
                            />
                            <span>ok</span>
                          </div>
                        )}
                      </div>
                    ) : innerStep == 1 ? (
                      <div className={style.loading}>
                        <div>
                          Protection for this account will start with Erasa.
                        </div>
                        <div>
                          <img
                            src="/images/erasa/protectedAccount/loading.webp"
                            alt=""
                          />
                          <span>Check your accounts</span>
                        </div>
                      </div>
                    ) : (
                      <div className={style.info}>
                        <div className={style.left}>
                          <div>
                            <img
                              src={
                                info?.avatar_url ||
                                "/images/dashboard/defaultAvatar.png"
                              }
                              alt=""
                            />
                            <img
                              src={platFormsIconsMap.get(info?.social_type)}
                              alt=""
                            />
                          </div>
                          <div>
                            <div>{info?.name || "-"}</div>
                            <div>{info?.profile_description || "-"}</div>
                          </div>
                        </div>
                        <div
                          className={style.right}
                          onClick={() => setInnerStep(0)}
                        >
                          <CloseOutlined />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={style.desc}>
                    Just one more step: add this to your bio to proceed.
                  </div>
                  <div className={style.copyDesc}>
                    <div>
                      <img
                        src="/images/erasa/protectedAccount/right.webp"
                        alt=""
                      />
                      All content is copyrighted . Protection enforced by
                      erasa.ai.
                    </div>
                    <div
                      onClick={() =>
                        handleCopy(
                          "All content is copyrighted . Protection enforced by erasa.ai"
                        )
                      }
                    >
                      <CopyOutlined />
                    </div>
                  </div>
                </>
              )}
              {step == 0 && innerStep == 2 ? (
                <div className={style.btn_c1}>
                  <Button onClick={resetStatus}>Cancel</Button>

                  <Button onClick={handleNext}>Next</Button>
                </div>
              ) : step == 1 ? (
                <div className={style.btn_c2}>
                  <Button onClick={resetStatus}>Cancel</Button>
                  {isCopy ? (
                    <Button
                      onClick={handlVerify}
                      className={style.copy}
                      style={{}}
                    >
                      Verify
                    </Button>
                  ) : (
                    <Button
                      onClick={handlVerify}
                      loading={step1Loading}
                      className={style.noCopy}
                    >
                      Skip for Now
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {isMobile ? (
              <></>
            ) : (
              <div>
                <img
                  src={
                    step == 0
                      ? "/images/erasa/protectedAccount/step1.png"
                      : "/images/erasa/protectedAccount/step2.webp"
                  }
                  style={{ height: "100%" }}
                  alt=""
                />
              </div>
            )}
          </div>
        </OurModal>
        <OurModal
          isModalOpen={isDisclaimerModal}
          handleCancel={() => setIsDisclasimerModal(false)}
          width={360}
        >
          <div className={style.disclaimer}>
            <div className={style.title}>DISCLAIMER</div>
            <div className={style.desc}>
              User must have legal copyright ownership or authorized permission
              to request content removal via this account.
            </div>
            <div className={style.btns}>
              <Button onClick={() => setIsDisclasimerModal(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (isHome) {
                    router.push("/protected-Accounts");
                  } else {
                    setIsDisclasimerModal(false);
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </OurModal>
      </div>
    );
  }
);

export default Index;
