export const platFormsIconsMap = new Map([
  ["ONLYFANS", "/images/erasa/platforms/onlyfans.png"],
  ["TIKTOK", "/images/erasa/platforms/tiktok.png"],
  ["X", "/images/erasa/platforms/space.png"],
  ["INSTAGRAM", "/images/erasa/platforms/ins.png"],
  ["PATREON", "/images/erasa/platforms/patreon.png"],
  ["FANVUE", "/images/erasa/platforms/fanvue.png"],
  ["MANYVIDS", "/images/erasa/platforms/manyvids.png"],
  ["CHATURBATE", "/images/erasa/platforms/chaturbate.png"],
  ["FANSLY", "/images/erasa/platforms/fansly.png"],
  ["REDDIT", "/images/erasa/platforms/reddit.png"],
  ["", "/images/erasa/platforms/other.png"],
]);
